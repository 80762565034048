import React from "react";
// import classNames from "classnames";
import { Text } from "@atoms";

const CbCopy = ({ copy }) => {
  return <Text richText>{copy}</Text>;
};

CbCopy.defaultProps = {};

export default CbCopy;
