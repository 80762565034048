import React from "react";
import LogoColor from "@svg/logoColor.svg";

const Logo = () => {
  return (
    <div className="flex items-center text-sm font-bold">
      <div name="logoColor" className="svg-icon--fit-height h-8">
        <LogoColor />
      </div>
    </div>
  );
};

export default Logo;
