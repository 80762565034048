import React, { useState } from "react";
import classNames from "classnames";
import { m } from "framer-motion";
import { Icon, Text, ButtonAlt } from "@atoms";

const ModuleChip = ({
  color,
  descriptor,
  icon,
  mode,
  onClick,
  state,
  title,
  url,
}) => {
  const variants = {
    open: {
      height: "auto",
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
    close: {
      height: 0,
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
  };

  const [hovered, setHovered] = useState(false);
  const [active, setActive] = useState(false);

  return (
    <m.div
      variants={{
        show: { y: 0, opacity: 1 },
        hidden: { y: 10, opacity: 0 },
      }}
      transition={{ duration: 0.5, type: "tween" }}
      className={classNames("rounded bg-white shadow-md", {
        "border-2 py-3 pl-3 duration-500": mode === "add",
        "border-white hover:border-black": mode === "add" && !state,
        "border-teal": mode === "add" && state,
        "p-5": mode === "expand",
      })}
      onHoverStart={() => {
        setActive(mode === "expand");
        setHovered(true);
      }}
      onHoverEnd={() => {
        setActive(false);
        setHovered(false);
      }}
    >
      {/* icon and title container */}
      <div className="flex w-full flex-col">
        <div
          className={classNames("flex items-center justify-between", {
            "gap-4": mode === "expand",
          })}
        >
          <m.button
            type="button"
            className={classNames("curser-default flex flex-1 items-center", {
              "gap-2": mode === "add",
              "gap-4": mode !== "add",
            })}
            onTap={() =>
              hovered && mode !== "expand" ? null : setActive(!active)
            }
          >
            <div
              className={classNames(
                "flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full",
                {
                  "bg-pink": color === "pink",
                  "bg-purple": color === "purple",
                  "bg-teal": color === "teal",
                }
              )}
            >
              <div
                className="svg-icon--fit-height flex h-full w-full items-center justify-center p-[20%] text-white"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: icon?.[0]?.code }}
              />
            </div>
            <div className="text-left">
              <Text
                className={classNames(
                  "font-heading font-bold leading-tighter",
                  {
                    "text-sm": mode === "add",
                    "text-base": mode === "expand",
                  }
                )}
              >
                {title}
              </Text>
            </div>
          </m.button>
          <m.button
            type="button"
            onTap={() => (mode === "expand" ? setActive(!active) : null)}
            onClick={mode === "add" ? onClick : null}
            className={classNames("flex-shrink-0 self-stretch", {
              "pl-2 pr-4": mode === "add",
            })}
          >
            {descriptor && mode === "expand" && (
              <Icon
                name="chevron"
                className={classNames("h-2 w-2 flex-shrink-0 duration-500", {
                  "-rotate-90": hovered || active,
                  "rotate-90": !hovered && !active,
                })}
              />
            )}
            {mode === "add" && (
              <Icon
                name={state ? "check" : "add"}
                className={classNames(
                  "ml-auto h-4 w-4 flex-shrink-0 duration-500",
                  {
                    "text-black": !state,
                    "text-teal": state,
                  }
                )}
              />
            )}
          </m.button>
        </div>
      </div>

      {/* descriptor and button container */}
      <div className="ml-16">
        <m.div
          variants={variants}
          initial="close"
          animate={hovered || active ? "open" : "close"}
          className={classNames(
            "flex flex-col gap-2 overflow-hidden pr-4 text-left transition-spacing duration-500",
            {
              "mt-4": hovered || active,
              "mt-0": !hovered && !active,
            }
          )}
        >
          <Text variant="xs">{descriptor}</Text>
          {url && (
            <ButtonAlt to={url} small className="pb-1">
              Learn More
            </ButtonAlt>
          )}
        </m.div>
      </div>
    </m.div>
  );
};

ModuleChip.defaultProps = {
  mode: "expand",
};

export default ModuleChip;
