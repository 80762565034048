import React from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import { Image, Text, ButtonAlt } from "@atoms";
import { AppLink } from "@base";

const Wrapper = ({
  children,
  className: _className,
  condition,
  onClick,
  url,
}) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {condition || onClick ? (
        <AppLink to={url} onClick={onClick} className={classNames(_className)}>
          {children}
        </AppLink>
      ) : (
        <div className={classNames(_className)}>{children}</div>
      )}
    </>
  );
};

const ArticleCard = ({
  authors,
  date,
  descriptor,
  feature,
  image,
  title,
  type,
  url,
}) => {
  return (
    <Wrapper
      condition={!feature}
      url={url}
      className={classNames("h-full", {
        group: !feature,
      })}
    >
      <div
        className={classNames("flex flex-col text-left", {
          "h-full rounded-lg bg-white shadow-md": !feature,
        })}
      >
        <Wrapper condition={feature} url={url}>
          <Image
            image={image}
            aspectRatio={[16, 9]}
            className={classNames({
              "rounded-lg": feature,
              "rounded-t-lg": !feature,
            })}
          />
        </Wrapper>
        <div
          className={classNames("mt-6 flex flex-col items-start", {
            "gap-5": feature,
            "gap-4 px-6 pb-6": !feature,
          })}
        >
          <Text
            variant="h6"
            className={classNames({
              "order-1": feature,
              "order-2": !feature,
            })}
          >
            {title}
          </Text>
          {/* authors and date */}
          <div className="order-2 flex flex-wrap gap-2 sm:gap-y-2 sm:gap-x-4">
            <span className="flex w-full gap-1 text-xs font-medium font-bold text-teal sm:w-auto">
              by
              <span className="text-black">
                {authors?.length
                  ? authors.map(author => author.title).join(", ")
                  : "the BridgeCare team"}
              </span>
            </span>
            <span className="flex w-full gap-1 text-xs font-medium font-bold text-teal sm:w-auto">
              on
              <span className="text-black">
                {dayjs(date).format("MMMM D, YYYY")}
              </span>
            </span>
          </div>
          <Text
            variant="tag"
            className={classNames("text-teal", {
              "order-3": feature,
              "order-1": !feature,
            })}
          >
            {type}
          </Text>
          {feature && (
            <Text variant="sm" className="order-4">
              {descriptor}
            </Text>
          )}
          <ButtonAlt to={feature ? url : null} className="order-5">
            Read more
          </ButtonAlt>
        </div>
      </div>
    </Wrapper>
  );
};

ArticleCard.defaultProps = {
  full: false,
};

export default ArticleCard;
