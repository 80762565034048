import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { m } from "framer-motion";
import usePagination from "@hooks/usePagination";

import { Button, Icon } from "@atoms";

const Pagination = ({
  className: _className,
  component: Component,
  data,
  pageSize,
  rangeLimit,
  showNavigation,
  variants,
}) => {
  const pages = Math.ceil((data?.length || 0) / pageSize);
  const [currentPage, setCurrentPage] = useState(1);

  // chunk the data into pages
  const paginatedData = data?.reduce((result, item, index) => {
    const chunkIndex = Math.floor(index / pageSize);

    if (!result[chunkIndex]) {
      // eslint-disable-next-line no-param-reassign
      result[chunkIndex] = []; // start a new chunk
    }

    result[chunkIndex].push(item);

    return result;
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const paginationRange = usePagination({
    pages,
    currentPage,
    rangeLimit,
  });

  // store page numbers in state to that the mapped data updates
  const [pageNumbers, setPageNumbers] = useState(paginationRange);

  useEffect(() => {
    setPageNumbers(paginationRange);
  }, [paginationRange]);

  const nextPage = () => {
    setCurrentPage(page => (page < pages ? page + 1 : page));
  };
  const prevPage = () => {
    setCurrentPage(page => (page > 1 ? page - 1 : 1));
  };
  // use the number within the button to set the page
  const changePage = event => {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <ul className={classNames(_className)}>
        {paginatedData[currentPage - 1]?.map((item, i) => (
          <m.li
            key={item?.uid}
            initial="exit"
            animate="enter"
            exit="exit"
            variants={variants}
          >
            <Component key={item?.uid} order={i + 1} {...item} />
          </m.li>
        ))}
      </ul>
      {pages > 1 && (
        <div className="flex flex-wrap gap-2">
          {/* previous button */}
          {showNavigation && (
            <Button onClick={prevPage} color="purple" size="xs">
              <Icon name="chevron" className="h-3 w-3 rotate-180" />
            </Button>
          )}
          {/* page number buttons */}
          {pageNumbers?.map((pageNumber, i) => {
            if (pageNumber === "...") {
              return (
                <span
                  // eslint-disable-next-line react/no-array-index-key
                  key={pageNumber + i}
                  className="py-2 px-4 text-xs font-bold leading-none text-black"
                >
                  {pageNumber}
                </span>
              );
            }
            return (
              // using vanilla button here to allow for active button styling
              <button
                // eslint-disable-next-line react/no-array-index-key
                key={pageNumber}
                type="button"
                className={classNames(
                  "rounded-md border py-2 px-4 text-xs font-bold leading-none duration-500 hover:bg-purple hover:text-white",
                  {
                    "border-black text-black": currentPage !== pageNumber,
                    "border-purple bg-purple text-white":
                      currentPage === pageNumber,
                  }
                )}
                onClick={changePage}
              >
                <span>{pageNumber}</span>
              </button>
            );
          })}
          {/* next button */}
          {showNavigation && (
            <Button onClick={nextPage} color="purple" size="xs">
              <Icon name="chevron" className="h-3 w-3" />
            </Button>
          )}
        </div>
      )}
    </>
  );
};

Pagination.defaultProps = {
  pageSize: 9,
  rangeLimit: 1,
  showNavigation: true,
};

export default Pagination;
