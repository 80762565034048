import React from "react";
import classNames from "classnames";
import { Text, ButtonAlt } from "@atoms";
import { AppLink } from "@base";

const Card = ({ className: _className, url, heading, descriptor }) => {
  return (
    <AppLink to={url} className={classNames("group h-full", _className)}>
      <div className="flex h-full flex-col items-start justify-between gap-3 rounded-lg bg-white p-4 shadow-lg duration-500 group-hover:bg-black group-hover:text-white sm:p-8">
        <div className="flex flex-col gap-3">
          <Text variant="h3" className="lg:min-h-[4rem]">
            {heading}
          </Text>
          <Text variant="sm">{descriptor}</Text>
        </div>
        <ButtonAlt color="whiteBlack">Learn more</ButtonAlt>
      </div>
    </AppLink>
  );
};

Card.defaultProps = {};

export default Card;
