import React from "react";
import { m } from "framer-motion";
import { Text, Image, Icon, ButtonAlt, Button } from "@atoms";

const SolutionOverviewCard = ({ heading, copy, benefits, image, links }) => {
  const ancestor = {
    hidden: {
      opacity: 0,
      x: "-10%",
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      x: "10%",
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        type: "tween",
        duration: 0.5,
        staggerChildren: benefits?.length ? benefits.length * 0.25 : 0.75,
      },
    },
  };

  const parent = {
    hidden: {
      opacity: 0,
      x: "-10%",
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
        type: "tween",
        staggerChildren: 0.25,
      },
    },
  };

  const item = {
    hidden: {
      opacity: 0,
      x: "-10%",
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
        type: "tween",
      },
    },
  };

  return (
    <div className="flex flex-wrap gap-24 sm:flex-nowrap">
      <m.div
        variants={ancestor}
        initial="hidden"
        whileInView="show"
        exit="exit"
        viewport={{ margin: "0px 0px -15% 0px" }}
        className="flex w-full flex-col gap-10 sm:w-1/2"
      >
        <m.div className="flex flex-col gap-5">
          <Text variant="h3">{heading}</Text>
          <Text variant="body">{copy}</Text>
          <m.ul variants={parent} className="flex flex-col gap-6">
            {benefits.map(b => (
              <m.li className="flex gap-2" key={b} variants={item}>
                <Icon
                  name="checkmark"
                  className="h-3.5 w-3.5 pt-[.125rem] text-purple"
                />
                <span className="font-heading text-base font-bold">{b}</span>
              </m.li>
            ))}
          </m.ul>
        </m.div>
        <m.div variants={parent} className="flex flex-wrap gap-3 gap-y-6">
          {links.map(l => {
            switch (l.type) {
              case "secondaryLink":
                return (
                  <ButtonAlt key={l.uid} to={l.secondaryLink.url}>
                    {l.secondaryLink.text}
                  </ButtonAlt>
                );
              case "button":
                return (
                  <Button key={l.uid} to={l.button.url} variant="sm">
                    {l.button.text}
                  </Button>
                );
              default:
                return null;
            }
          })}
        </m.div>
      </m.div>
      <m.div
        initial={{ opacity: 0, scale: 0 }}
        exit={{ opacity: 0, x: "10%" }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5, type: "tween" }}
        className="relative flex w-full items-center justify-center sm:w-[60%]"
      >
        {image && <Image image={image} />}
      </m.div>
    </div>
  );
};

export default SolutionOverviewCard;
