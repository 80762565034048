import React, { useState } from "react";
import classNames from "classnames";
import { m } from "framer-motion";
import { Icon } from "@atoms";
import { AppLink } from "@base";

const MobileLink = ({ text, url, sublinks, isDark }) => {
  const [showLinks, setShowLinks] = useState(false);

  const defaults = {
    type: "tween",
    duration: 0.3,
  };

  const listVariants = {
    open: {
      height: "auto",
      transition: {
        ...defaults,
      },
    },
    closed: {
      height: 0,
      transition: {
        ...defaults,
      },
    },
  };

  return (
    <li>
      <div className="flex justify-between py-3">
        <AppLink
          variant="label"
          to={url}
          className={classNames(
            "font-heading font-bold underline decoration-transparent decoration-2 underline-offset-[.25rem] duration-500 hover:decoration-teal",
            {
              "decoration-teal": showLinks,
              "text-black": !isDark,
              "text-white": isDark,
            }
          )}
        >
          {text}
        </AppLink>
        {!!sublinks?.length && (
          <button
            type="button"
            onClick={() => setShowLinks(!showLinks)}
            aria-label="show the sublinks"
          >
            <Icon
              name="chevron"
              className={classNames("h-2 w-2 duration-500", {
                "rotate-90": !showLinks,
                "-rotate-90": showLinks,
                "text-inherit": !isDark,
                "text-teal": isDark,
              })}
            />
          </button>
        )}
      </div>
      {!!sublinks?.length && (
        <m.ul
          initial="closed"
          animate={showLinks ? "open" : "closed"}
          variants={listVariants}
          className="overflow-hidden px-4"
        >
          {sublinks?.map(sublink => {
            const { uid: subuid, text: subtext, url: suburl } = sublink;
            return (
              <li className="py-3" key={subuid}>
                <AppLink
                  to={suburl}
                  className={classNames(
                    "font-heading text-sm font-bold underline decoration-transparent decoration-2 underline-offset-[.25rem] duration-500 hover:decoration-teal",
                    {
                      "text-black": !isDark,
                      "text-white": isDark,
                    }
                  )}
                >
                  {subtext}
                </AppLink>
              </li>
            );
          })}
        </m.ul>
      )}
    </li>
  );
};

MobileLink.defaultProps = {};

export default MobileLink;
