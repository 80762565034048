import React from "react";
import { Text, Icon } from "@atoms";
import useDefaultsData from "@staticQueries/DefaultsQuery";
import Form from "@molecules/Form";

const MiniNewsletter = () => {
  const { newsletter } = useDefaultsData();
  const { heading, descriptor, formId } = newsletter;

  return (
    <div className="relative overflow-hidden rounded-lg bg-teal p-5 md:p-10">
      <div className="relative z-10 flex flex-col gap-3">
        <Text variant="h4">{heading}</Text>
        <Text variant="sm">{descriptor}</Text>
        <Form
          formId={formId}
          condensed
          dark={false}
          requiredOnly
          successMessage="Thank you for subscribing to our newsletter."
        />
      </div>
      <div className="absolute -bottom-20 -right-10 z-0 w-[12rem] overflow-hidden sm:w-[16rem]">
        <Icon name="watermark" className="w-full" />
      </div>
    </div>
  );
};

MiniNewsletter.defaultProps = {};

export default MiniNewsletter;
