import React from "react";
import { Text } from "@atoms";
import ModuleChip from "./ModuleChip";

const ModuleList = ({ modules }) => {
  return (
    <div className="flex flex-col items-center gap-10">
      <div className="w-full border-b-2 border-teal pb-2 text-teal-dark">
        <Text variant="h3">Services Provided</Text>
      </div>
      {/* TODO: make this animate more smoothly */}
      {!!modules?.length && (
        <div className="flex flex-row flex-wrap gap-5 md:flex-col">
          {modules.map(module => {
            return (
              <div className="flex-grow sm:flex-[0_1_calc(50%-1.25rem)] md:flex-1">
                <ModuleChip key={module.uid} {...module} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

ModuleList.defaultProps = {};

export default ModuleList;
