import React from "react";
import classNames from "classnames";
import { Text, ButtonAlt } from "@atoms";
import { AppLink } from "@base";

const ModuleCard = ({
  className: _className,
  color,
  descriptor,
  horizontal,
  icon,
  title,
  url,
}) => {
  return (
    <AppLink to={url} className={classNames("group", _className)}>
      <div className="flex h-full flex-col justify-between gap-4 rounded-lg bg-white p-6 shadow-lg">
        <div
          className={classNames("flex w-full items-center gap-4", {
            "h-full flex-col text-center": !horizontal,
          })}
        >
          {/* icon and title container */}
          <div
            className={classNames(
              "relative flex items-center justify-center rounded-full",
              {
                "h-12 w-12 flex-shrink-0": horizontal,
                "h-16 w-16 sm:h-20 sm:w-20": !horizontal,
                "bg-teal": color === "teal",
                "bg-pink": color === "pink",
                "bg-purple": color === "purple",
              }
            )}
          >
            <div
              className="svg-icon--fit-height flex h-full w-full items-center justify-center p-[20%] text-white"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: icon[0]?.code }}
            />
          </div>
          <Text
            className={classNames("font-heading font-bold leading-tighter", {
              "text-base": horizontal,
              "text-xl lg:min-h-[2.75rem]": !horizontal,
            })}
          >
            {title}
          </Text>
        </div>

        {/* descriptor and button container */}
        <div
          className={classNames("flex h-full flex-col justify-between", {
            "ml-16 items-start gap-2 text-left": horizontal,
            "items-center gap-4 text-center": !horizontal,
          })}
        >
          <Text variant="xs">{descriptor}</Text>
          {url && <ButtonAlt>Learn more</ButtonAlt>}
        </div>
      </div>
    </AppLink>
  );
};

ModuleCard.defaultProps = {};

export default ModuleCard;
