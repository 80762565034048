import React from "react";
import classNames from "classnames";
import { Text, ButtonAlt } from "@atoms";
import { AppLink } from "@base";

const RelatedArticles = ({ articles, className: _className }) => {
  return (
    <div className={classNames(_className)}>
      <Text variant="h3" className="border-b-2 border-teal text-teal-dark">
        Related Articles
      </Text>
      <div className="mt-10 flex flex-col gap-10">
        {articles?.map(article => {
          const { type, title, url } = article;
          return (
            <AppLink key={title} to={url} className="group">
              <div className="flex flex-col gap-3">
                <Text variant="tag" className="text-teal">
                  {type}
                </Text>
                <Text variant="h6">{title}</Text>
                <ButtonAlt>Read</ButtonAlt>
              </div>
            </AppLink>
          );
        })}
      </div>
    </div>
  );
};

RelatedArticles.defaultProps = {};

export default RelatedArticles;
