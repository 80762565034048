import React, { useState } from "react";
import classNames from "classnames";
import { m } from "framer-motion";
import { Text, ButtonAlt, Icon } from "@atoms";

const SolutionCard = ({
  className: _className,
  descriptor,
  modules,
  title,
  url,
  onClick,
  cta,
  customIcon,
}) => {
  const [active, setActive] = useState(null);

  const tooltips = {
    show: {
      y: "0%",
      opacity: 1,
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
    hide: {
      y: "20%",
      opacity: 0,
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
  };

  return (
    <div className="flex h-full flex-grow flex-col justify-between gap-8 rounded-lg bg-white p-6 shadow-lg">
      <div className="flex flex-col items-start gap-8 text-left">
        <div className="flex flex-col gap-8">
          <Text variant="h6" className="sm:min-h-[2.75rem]">
            {title}
          </Text>
          <Text variant="xs">{descriptor}</Text>
        </div>
        {customIcon && (
          <div className="flex w-full flex-grow items-center justify-center">
            <Icon name={customIcon} className="h-28 w-28" />
          </div>
        )}
        {!!modules?.length && (
          <div className="flex flex-col gap-3">
            <Text
              variant="xs"
              className="font-heading font-bold text-teal-dark"
            >
              Modules included:
            </Text>
            <div className="flex flex-wrap gap-3">
              {modules.map((module, i) => {
                const { uid, icon, title: tooltip, color } = module;
                return (
                  <m.div
                    key={uid}
                    onHoverStart={() => setActive(i)}
                    onHoverEnd={() => setActive(null)}
                    onTap={() => setActive(active === i ? null : i)}
                    className={classNames(
                      "relative z-50 flex h-10 w-10 items-center justify-center rounded-full",
                      {
                        "bg-teal": color === "teal",
                        "bg-pink": color === "pink",
                        "bg-purple": color === "purple",
                      }
                    )}
                  >
                    <div
                      className="svg-icon--fit-height flex h-full w-full items-center justify-center p-[20%] text-white"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: icon[0]?.code }}
                    />
                    <m.div
                      initial="hide"
                      animate={active === i ? "show" : "hide"}
                      variants={tooltips}
                      className={classNames(
                        "absolute bottom-14 z-20 rounded-md bg-black p-2 text-white",
                        {
                          "pointer-events-none": active !== i,
                          "left-0": i % 5 < 3,
                          "right-0": i % 5 >= 3,
                        }
                      )}
                    >
                      <div
                        className={classNames(
                          "absolute -bottom-2 h-0 w-0 border-l-[0.5rem] border-r-[0.5rem] border-t-[0.5rem] border-l-transparent border-r-transparent border-t-black",
                          {
                            "left-3": i % 5 < 3,
                            "right-3": i % 5 >= 3,
                          }
                        )}
                      />
                      <Text variant="xs" className="whitespace-nowrap">
                        {tooltip}
                      </Text>
                    </m.div>
                  </m.div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      {(url || onClick) && (
        <ButtonAlt to={url}>{cta || "Learn more"}</ButtonAlt>
      )}
    </div>
  );
};

SolutionCard.defaultProps = {};

export default SolutionCard;
