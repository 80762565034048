import React from "react";
import loadable from "@loadable/component";
import { Text } from "@atoms";

const Video = loadable(() => import("../atoms/Video"));

const CbVideo = ({ url, caption }) => {
  return (
    <div className="flex flex-col gap-3">
      <Video url={url} />
      <Text variant="xs" className="max-w-xl italic">
        {caption}
      </Text>
    </div>
  );
};

CbVideo.defaultProps = {};

export default CbVideo;
