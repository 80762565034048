import React from "react";
import useSolutionsData from "@staticQueries/SolutionsQuery";
import useModulesData from "@staticQueries/ModulesQuery";
import { AppLink } from "@base";
import { ButtonAlt } from "@atoms";

const SolutionsDropdown = ({ model }) => {
  // eslint-disable-next-line no-unused-vars
  const [activeDropdown, setActiveDropdown] = model;

  const solutions = useSolutionsData();
  const modules = useModulesData();

  return (
    <div className="flex w-full whitespace-normal">
      <div className="flex w-full flex-col gap-3 p-4 sm:w-1/2 md:w-2/3 md:p-6 lg:w-3/4 lg:p-10">
        <span className="font-heading text-xs font-medium uppercase tracking-0.5 text-teal">
          solutions
        </span>
        <ul className="columns-1 gap-6 sm:columns-2 lg:columns-3">
          {solutions.map(s => (
            <li
              key={s.uid}
              className="group/link mb-4 break-inside-avoid border-b border-teal pb-4"
            >
              <AppLink
                className="flex flex-col gap-1 text-xs leading-tight"
                to={s.url}
                onClick={() => setActiveDropdown(null)}
              >
                <span className="font-heading font-bold underline decoration-teal/0 decoration-2 underline-offset-[.25rem] duration-500 group-hover/link:decoration-teal">
                  {s.title}
                </span>
                <span>{s.descriptor}</span>
              </AppLink>
            </li>
          ))}
        </ul>
        <div className="mt-auto">
          <ButtonAlt
            to="/solutions"
            small
            onClick={() => setActiveDropdown(null)}
          >
            View All
          </ButtonAlt>
        </div>
      </div>
      <div className="bg-offwhite p-4 sm:w-1/2 md:w-1/3 md:p-6 lg:w-1/4 lg:p-10">
        <span className="font-heading text-xs font-medium uppercase tracking-0.5 text-teal">
          modules
        </span>
        <ul className="gap-6 pt-3">
          {modules.map(m => (
            <li key={m.uid} className="mb-3">
              <AppLink
                className="font-heading text-xs font-bold underline decoration-transparent decoration-2 underline-offset-[.25rem] duration-500 hover:decoration-teal"
                to={m.url}
                onClick={() => setActiveDropdown(null)}
              >
                {m.title}
              </AppLink>
            </li>
          ))}
        </ul>
        <div className="text-xs">
          <ButtonAlt
            to="/solutions/modules"
            small
            onClick={() => setActiveDropdown(null)}
          >
            View All
          </ButtonAlt>
        </div>
      </div>
    </div>
  );
};

export default SolutionsDropdown;
