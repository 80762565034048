import React, { useState, useEffect, useRef } from "react";
import { m, AnimatePresence } from "framer-motion";
import classNames from "classnames";
import useWindowSize from "@hooks/useWindowSize";
import { Icon } from "@atoms";

const wrap = (min, max, value) => {
  const rangeSize = max - min;
  return ((((value - min) % rangeSize) + rangeSize) % rangeSize) + min;
};

const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const Carousel = ({
  children,
  className: _className,
  maxVisible,
  showInactive,
}) => {
  const slides = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child);
    }
    return child;
  });

  const [limit, setLimit] = useState(maxVisible || 1);
  // page is a value that changes with every click
  // in order to trigger an update
  const [[page, direction], setPage] = useState([0, 0]);
  const [newSlides, setNewSlides] = useState(slides || []);

  const slideWidth = `${100 / limit}%`;

  const carouselContainer = useRef();
  const { innerWidth: windowWidth } = useWindowSize();

  // this moves the position of items in the array
  const moveSlide = newDirection => {
    newDirection > 0
      ? newSlides.push(...newSlides.splice(0, newDirection))
      : newSlides.unshift(...newSlides.splice(newDirection));
    setPage([page + newDirection, newDirection]);
  };

  // calculate # of slides that are visible
  const calculateVisibleSlides = width => {
    if (maxVisible > 1) {
      const screens = {
        xxs: "350", // for super small screens
        sm: "700", // bigger than most phones
        md: "850",
        lg: "1200",
        xl: "1600", // larger than 15" macbook pro
        xxl: "2000",
      };
      // configure number of slides based on screen size
      const noSlides = {
        sm: 1,
        md: 2,
        lg: 3,
        xl: maxVisible,
      };
      // match screen
      const matchedScreen = Object.keys(screens).find(screen => {
        return width < screens[screen];
      });
      // return match
      if (matchedScreen && noSlides[matchedScreen] <= maxVisible) {
        return noSlides[matchedScreen];
      }
    }
    return maxVisible;
  };

  // this variable allows slides to loop infinitely,
  // even if there is only one slide
  const imageIndex = wrap(0, slides.length, page);

  useEffect(() => {
    setNewSlides(newSlides);
  }, [imageIndex]);

  // calculate visible slides on window resize
  useEffect(() => {
    if (carouselContainer.current) {
      const resizedSlides = calculateVisibleSlides(windowWidth);
      setLimit(resizedSlides);
    }
  }, [windowWidth]);

  const variants = {
    enter: dir => {
      return {
        zIndex: 0,
        x: dir > 0 ? 100 : -100,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: dir => {
      return {
        zIndex: 0,
        x: dir < 0 ? 100 : -100,
        opacity: 0,
      };
    },
  };

  return (
    <div ref={carouselContainer} className="flex flex-col items-center">
      <div className="relative w-full">
        {/* prev button */}
        <div className="absolute -left-4 top-0 bottom-0 z-20 flex items-center justify-center lg:-left-8 xl:-left-24">
          <button
            type="button"
            onClick={() => {
              moveSlide(-1);
            }}
            aria-label="Go to the previous slide"
            className="group flex items-center justify-center rounded-full bg-pink p-3 text-white duration-500 hover:bg-teal-dark"
          >
            <Icon
              name="chevron"
              className="relative right-0 h-2 w-2 rotate-180 duration-500"
            />
          </button>
        </div>

        {/* slides */}
        <AnimatePresence initial={false} custom={direction}>
          <m.div
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);
              if (swipe < -10000) {
                moveSlide(1);
              } else if (swipe > 10000) {
                moveSlide(-1);
              }
            }}
            className={classNames("flex", _className)}
          >
            {newSlides?.map((slide, i) => {
              if (i >= 0 && i < limit) {
                return (
                  <m.div
                    key={slide.key || `slide-${i}`}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{ duration: 0.5, type: "tween" }}
                    style={{ width: slideWidth }}
                  >
                    {slide}
                  </m.div>
                );
              }
              return null;
            })}
          </m.div>
        </AnimatePresence>

        {/* next button */}
        <div className="absolute -right-4 top-0 bottom-0 z-20 flex items-center justify-center lg:-right-8 xl:-right-24">
          <button
            type="button"
            onClick={() => {
              moveSlide(1);
            }}
            aria-label="Go to the next slide"
            className="group flex items-center justify-center rounded-full bg-pink p-3 text-white duration-500 hover:bg-teal-dark"
          >
            <Icon
              name="chevron"
              className="relative right-0 h-2 w-2 duration-500"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

Carousel.defaultProps = {
  maxVisible: 1,
};

export default Carousel;
