import React from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import { Text, Image } from "@atoms";

const ArticleHero = ({
  authors,
  className: _className,
  date,
  image,
  theme,
  title,
  type,
}) => {
  const isDark =
    theme === "darkTeal" || theme === "darkerTeal" || theme === "black";

  return (
    <div className={classNames("flex flex-col gap-6", _className)}>
      <div className="flex flex-col gap-5">
        <Text
          variant="h2"
          className={classNames({
            "text-white": isDark && type === "whitepaper",
          })}
        >
          {title}
        </Text>
        {type !== "whitepaper" && (
          <div className="flex flex-wrap text-teal sm:gap-10">
            <Text variant="bigTag">{type}</Text>
          </div>
        )}
        {/* authors and date */}
        <div className="flex flex-wrap gap-2 sm:gap-4">
          <span className="flex w-full gap-1 text-xs font-medium font-bold text-teal sm:w-auto">
            by
            <span
              className={classNames({
                "text-white": isDark && type === "whitepaper",
                "text-black": !isDark || type !== "whitepaper",
              })}
            >
              {authors?.length
                ? authors.map(author => author.title).join(", ")
                : "the BridgeCare team"}
            </span>
          </span>
          <span className="flex w-full gap-1 text-xs font-medium font-bold text-teal sm:w-auto">
            on
            <span
              className={classNames({
                "text-white": isDark && type === "whitepaper",
                "text-black": !isDark || type !== "whitepaper",
              })}
            >
              {dayjs(date).format("MMMM D, YYYY")}
            </span>
          </span>
        </div>
      </div>
      {image && (
        <Image
          image={image}
          aspectRatio={[16, 9]}
          className="relative rounded-lg"
        />
      )}
    </div>
  );
};

ArticleHero.defaultProps = {};

export default ArticleHero;
