import React from "react";
import classNames from "classnames";
import { m } from "framer-motion";
import { useAppState } from "@state";
import { Container, Button } from "@atoms";
import MobileLink from "./MobileLink";

const MobileHeader = ({ nav, button, showSolutions, solutionsLink, theme }) => {
  const [{ layout }] = useAppState();

  // Mobile nav triggers
  const { hideNav } = layout;

  const variants = {
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        type: "tween",
      },
    },
    hide: {
      opacity: 0,
      transition: {
        duration: 0.5,
        type: "tween",
      },
    },
  };

  const background = {
    white: "bg-white",
    offwhite: "bg-offwhite",
    lightTeal: "bg-teal-light",
    lighterTeal: "bg-teal-lighter",
    darkTeal: "bg-teal-dark",
    darkerTeal: "bg-black",
  }[theme];

  const isDark =
    theme === "darkTeal" || theme === "darkerTeal" || theme === "black";

  if (nav) {
    return (
      <m.nav
        initial="hide"
        animate={hideNav ? "hide" : "show"}
        variants={variants}
        aria-label="Mobile navigation"
        aria-live={!hideNav ? "polite" : "off"}
        className={classNames(
          "absolute left-0 top-16 right-0 sm:top-20 md:top-24",
          {
            "pointer-events-none": hideNav,
          },
          background
        )}
      >
        <Container
          className={classNames("shadow-md", {
            "shadow-black": isDark,
          })}
        >
          <ul className="flex flex-col py-3 md:hidden">
            {showSolutions && (
              <MobileLink
                text={solutionsLink?.text}
                url={solutionsLink?.url}
                sublinks={[
                  {
                    uid: "solutions",
                    text: "Solutions",
                    url: "/solutions",
                  },
                  {
                    uid: "modules",
                    text: "Modules",
                    url: "/solutions/modules",
                  },
                ]}
                isDark={isDark}
              />
            )}
            {nav?.map(link => {
              return <MobileLink key={link.uid} isDark={isDark} {...link} />;
            })}
            {button && (
              <li className="py-3">
                <Button
                  to={button.url}
                  size="sm"
                  color={isDark ? "white" : "pink"}
                >
                  {button.text}
                </Button>
              </li>
            )}
          </ul>
        </Container>
      </m.nav>
    );
  }
  return null;
};

MobileHeader.defaultProps = {};

export default MobileHeader;
