import React from "react";
import classNames from "classnames";
import { Text, Icon } from "@atoms";
import { AppLink } from "@base";

const Breadcrumbs = ({ className: _className, color, crumbs }) => {
  const isDark =
    color === "pink" ||
    color === "purple" ||
    color === "teal" ||
    color === "darkTeal" ||
    color === "darkerTeal" ||
    color === "black";

  if (crumbs.length > 1) {
    return (
      <div
        className={classNames(
          "relative flex flex-wrap gap-x-5 gap-y-3",
          _className
        )}
      >
        {crumbs?.map((crumb, i) => {
          const { uid, uri, title } = crumb;
          if (i !== crumbs.length - 1) {
            return (
              <div key={uid} className="flex items-center gap-4">
                <AppLink
                  to={uri}
                  className={classNames(
                    "inline-flex items-center gap-1 font-heading text-sm font-bold leading-normal underline decoration-2 underline-offset-[.25rem] duration-500",
                    {
                      "text-teal-dark decoration-teal-dark hover:decoration-teal":
                        color === "default",
                      "text-teal-light decoration-teal-light hover:text-white hover:decoration-white":
                        isDark && color !== "default",
                      "text-teal decoration-teal hover:text-pink hover:decoration-pink":
                        !isDark && color !== "default",
                    }
                  )}
                >
                  {title}
                </AppLink>
                <Icon
                  name="chevron"
                  className={classNames("flex-0 h-auto w-1.5", {
                    "text-teal-dark": color === "default",
                    "text-teal-light": isDark && color !== "default",
                    "text-teal": !isDark && color !== "default",
                  })}
                />
              </div>
            );
          }
          return (
            <Text
              key={uid}
              className={classNames(
                "pointer-events-none font-heading text-sm font-bold leading-normal",
                {
                  "text-teal-dark": color === "default",
                  "text-teal-light": isDark && color !== "default",
                  "text-teal": !isDark && color !== "default",
                }
              )}
            >
              {title}
            </Text>
          );
        })}
      </div>
    );
  }
  return null;
};

Breadcrumbs.defaultProps = {};

export default Breadcrumbs;
