import React from "react";
import { AppLink } from "@base";
import { Icon } from "@atoms";

const FooterNav = ({ links, privacyLink, copyright, socials }) => {
  return (
    <ul className="columns-1 gap-8 pt-2 sm:columns-2 md:columns-3 lg:columns-4">
      {links.map(link => (
        <li key={link.uid} className="mb-12 break-inside-avoid">
          {link.url && (
            <AppLink
              to={link.url}
              className="text-sm font-bold text-teal transition duration-500 hover:text-teal-lighter"
            >
              {link.text}
            </AppLink>
          )}
          {!!link.sublinks?.length && (
            <ul className="mt-5 grid gap-2 text-[0.65rem]">
              {link.sublinks.map(l => (
                <li key={l.uid}>
                  <AppLink
                    to={l.url}
                    className="font-body font-medium leading-[1.2] transition duration-500 hover:text-teal"
                  >
                    {l.text}
                  </AppLink>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
      {/* legal */}
      <li className="mb-12 flex flex-col gap-2 text-[0.65rem]">
        <AppLink
          className="font-body font-medium underline decoration-teal/0 decoration-2 underline-offset-[.25rem] duration-500 hover:decoration-teal"
          to={privacyLink.url}
        >
          {privacyLink.text}
        </AppLink>
        <div>{`© ${new Date().getFullYear()} ${copyright?.replace(
          /<.*?>/g,
          ""
        )}`}</div>
      </li>
      {/* social */}
      {!!socials?.length && (
        <li className="flex items-center gap-2 text-[0.65rem]">
          <span>Follow Us:</span>
          {socials.map(s => (
            <AppLink
              key={s.uid}
              className="flex items-center justify-center rounded-full bg-pink p-1.5 font-body font-medium text-white duration-500 hover:bg-teal"
              to={s.url}
            >
              <span className="sr-only">{s.platform}</span>
              <Icon name={s.platform} className="h-3 w-3" fitHeight />
            </AppLink>
          ))}
        </li>
      )}
      {/*  */}
    </ul>
  );
};

export default FooterNav;
