import React from "react";
import classNames from "classnames";
import { Text, ButtonAlt, Image } from "@atoms";
import { AppLink } from "@base";

const CaseStudyCard = ({ audiences, heading, url, image, vertical }) => {
  return (
    <AppLink to={url} className="group">
      <div className="flex h-full min-h-[16rem] flex-wrap overflow-hidden rounded-lg bg-white shadow-lg">
        <div
          className={classNames(
            "relative aspect-video w-full sm:aspect-auto sm:w-1/2 md:aspect-video md:w-full",
            {
              "lg:aspect-auto lg:w-1/2": !vertical,
            }
          )}
        >
          {image && <Image image={image} fill ixParams={{ crop: "faces" }} />}
        </div>
        <div
          className={classNames(
            "flex w-full flex-col items-start p-4 text-left sm:w-1/2 sm:p-6 md:w-full",
            {
              "lg:w-1/2": !vertical,
            }
          )}
        >
          <div className="mb-2 text-xs uppercase text-teal">Case Study</div>
          {!!audiences?.length && (
            <div className="mb-4 text-xs leading-normal text-black">
              {audiences.map(a => a.title).join(", ")}
            </div>
          )}
          <Text className="mb-6 font-heading text-xl font-bold leading-tighter">
            {heading}
          </Text>
          {url && (
            <div className="mt-auto">
              <ButtonAlt>Read more</ButtonAlt>
            </div>
          )}
        </div>
      </div>
    </AppLink>
  );
};

export default CaseStudyCard;
