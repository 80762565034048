import React from "react";
import classNames from "classnames";
import { Text, Image, Icon } from "@atoms";
import { m } from "framer-motion";

const TestimonialCard = ({
  color,
  image,
  name,
  padding,
  quote,
  small,
  title,
}) => {
  return (
    <m.blockquote
      initial="hidden"
      whileInView="show"
      viewport={{ margin: "0px 0px -15% 0px" }}
      className={classNames(
        "h-full overflow-hidden rounded-lg px-5 py-5 text-white shadow-lg sm:px-8",
        {
          "bg-teal-dark": color === "teal",
          "bg-purple": color === "purple",
        }
      )}
    >
      <div className="relative mx-auto flex h-full max-w-xl flex-col justify-between gap-8 sm:gap-16">
        <div className="relative z-10">
          <m.div
            variants={{
              hidden: {
                y: "50%",
                opacity: 0,
              },
              show: {
                y: 0,
                opacity: 1,
              },
            }}
            transition={{ duration: 0.8, type: "tween" }}
            className="mb-3 flex items-center justify-center"
          >
            <Icon className="h-6 w-6 text-teal sm:h-10 sm:w-10" name="quotes" />
          </m.div>
          <Text
            variant={small ? "smallQuote" : "quote"}
            className="quote text-center"
          >
            {quote}
          </Text>
        </div>
        <div
          className={classNames(
            "relative z-0 flex flex-col items-center gap-5 text-sm sm:flex-row sm:px-10",
            {
              "justify-center text-center": !image,
              "md:px-20": padding,
            }
          )}
        >
          {image && (
            <div className="relative order-2 h-20 w-20 flex-shrink-0 sm:order-1">
              <m.div
                variants={{
                  hidden: {
                    scale: 1,
                  },
                  show: {
                    scale: 2.25,
                  },
                }}
                transition={{ duration: 0.8, type: "tween" }}
                className={classNames(
                  "absolute left-0 bottom-0 top-0 h-20 w-20 w-full rounded-full",
                  {
                    "bg-teal": color === "teal",
                    "bg-purple-dark": color === "purple",
                  }
                )}
              />
              <Image
                image={image}
                aspectRatio={[1, 1]}
                className="overflow-hidden rounded-full"
              />
            </div>
          )}
          <cite className="relative z-10 order-1 flex flex-col gap-2 text-center not-italic sm:order-2 sm:text-left">
            <Text className="font-bold leading-tight">{name}</Text>
            <span className="font-medium leading-tight">{title}</span>
          </cite>
        </div>
      </div>
    </m.blockquote>
  );
};

TestimonialCard.defaultProps = {
  color: "teal",
  padding: true,
};

export default TestimonialCard;
