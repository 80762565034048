import React from "react";
import classNames from "classnames";
import { m } from "framer-motion";
import { Text, Image, Icon } from "@atoms";

const CbTestimonial = ({ testimonial, color }) => {
  const { image, quote, name, title } = testimonial;
  return (
    <blockquote
      className={classNames(
        "overflow-hidden rounded-lg p-8 pb-10 pt-5 text-white shadow-lg",
        {
          "bg-teal-dark": color === "teal",
          "bg-purple": color === "purple",
        }
      )}
    >
      <div className="relative mx-auto max-w-xl">
        <div className="relative z-10 mb-16">
          <div className="mb-3 flex items-center justify-center">
            <Icon className="h-10 w-10 text-teal" name="quotes" />
          </div>
          <Text variant="quote" className="quote">
            {quote}
          </Text>
        </div>
        <div className="relative z-0 -mb-5 flex items-center gap-5 text-sm">
          <div className="relative flex">
            <m.div
              variants={{
                hidden: {
                  scale: 1,
                  // y: "50%",
                  // opacity: 0,
                },
                show: {
                  scale: 2.25,
                  // y: 0,
                  // opacity: 1,
                },
              }}
              initial="hidden"
              whileInView="show"
              viewport={{ once: false }}
              transition={{ duration: 0.8, type: "tween" }}
              className={classNames(
                "absolute inset-0 h-24 w-24 w-full rounded-full",
                {
                  "bg-teal": color === "teal",
                  "bg-purple-dark": color === "purple",
                }
              )}
            />
            {image && (
              <Image
                image={image}
                aspectRatio={[1, 1]}
                className="h-24 w-24 overflow-hidden rounded-full"
              />
            )}
          </div>
          <cite className="relative z-10 flex flex-col gap-1 not-italic">
            <Text className="font-bold">{name}</Text>
            <Text>{title}</Text>
          </cite>
        </div>
      </div>
    </blockquote>
  );
};

CbTestimonial.defaultProps = {
  color: "teal",
};

export default CbTestimonial;
