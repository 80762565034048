import React, { useState } from "react";
import classNames from "classnames";
import { m } from "framer-motion";
import { Icon, Text, ButtonAlt } from "@atoms";
import { AppLink } from "@base";

const SolutionChip = ({
  className: _className,
  color,
  descriptor,
  hovered,
  icon,
  onClick,
  order,
  title,
  url,
}) => {
  const variants = {
    open: {
      height: "auto",
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
    close: {
      height: 0,
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
  };

  const isDark = [
    "bg-teal-dark",
    "bg-pink",
    "bg-pink",
    "bg-purple",
    "bg-purple-dark",
    "bg-black",
  ];

  const [active, setActive] = useState(false);

  return (
    <m.div
      initial={{ y: 10, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5, type: "tween" }}
      className={classNames(
        "group flex w-full flex-col rounded-lg bg-white p-3 shadow-md duration-500 md:p-0 md:hover:shadow-md",
        {
          "md:shadow-md": hovered === order,
          "md:shadow-none": hovered !== order,
        },
        _className
      )}
    >
      {/* desktop variant */}
      <AppLink
        to={url}
        onClick={onClick}
        className="hidden flex-col p-3 md:flex"
      >
        <div className="flex w-full items-center gap-4">
          <div className="flex w-full items-center justify-between gap-2 text-left">
            <Text
              className={classNames(
                "font-heading text-base font-bold leading-tighter underline decoration-2 underline-offset-[.25rem] duration-500 md:group-hover:decoration-teal",
                {
                  "md:decoration-teal": hovered === order,
                  "md:decoration-teal/0": hovered !== order,
                }
              )}
            >
              {title}
            </Text>
            <Icon
              name="chevron"
              className={classNames(
                "h-2 w-2 flex-shrink-0 text-teal duration-500 md:group-hover:opacity-100",
                {
                  "md:opacity-0": hovered !== order,
                  "md:opacity-100": hovered === order,
                }
              )}
            />
          </div>
        </div>
        <div className="ml-0 flex flex-col gap-2">
          <div className="mt-4 text-left duration-500">
            <Text variant="xs">{descriptor}</Text>
          </div>
        </div>
      </AppLink>

      {/* mobile title and icon */}
      <m.button
        type="button"
        onTap={() => setActive(!active)}
        className="flex w-full items-center gap-4 md:hidden"
      >
        <div
          className={classNames(
            "flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full",
            color
          )}
        >
          <div
            className={classNames(
              "svg-icon--fit-height flex h-full w-full items-center justify-center p-[20%]",
              {
                "text-white": isDark.includes(color),
                "text-black": !isDark.includes(color),
              }
            )}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: icon?.[0]?.code }}
          />
        </div>
        <div className="flex w-full items-center justify-between gap-2 text-left">
          <Text className="font-heading text-base font-bold leading-tighter">
            {title}
          </Text>
          <Icon
            name="chevron"
            className={classNames(
              "h-2 w-2 flex-shrink-0 text-teal duration-500",
              {
                "-rotate-90": active,
                "rotate-90": !active,
              }
            )}
          />
        </div>
      </m.button>
      {/* mobile descriptor */}
      <m.div
        variants={variants}
        initial="close"
        animate={active ? "open" : "close"}
        className={classNames(
          "ml-16 flex flex-col items-start gap-3 overflow-hidden text-left transition-spacing duration-500",
          {
            "mt-4": active,
            "mt-0": !active,
          }
        )}
      >
        <Text variant="xs">{descriptor}</Text>
        {url && (
          <ButtonAlt to={url} small className="pb-1">
            Learn More
          </ButtonAlt>
        )}
      </m.div>
    </m.div>
  );
};

export default SolutionChip;
