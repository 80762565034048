import React from "react";
// import classNames from "classnames";
import { Image, Text } from "@atoms";

const CbImages = ({ images, caption }) => {
  return (
    <>
      {images?.length === 1 && (
        <div className="relative">
          <Image image={images[0]} className="rounded-lg" />
        </div>
      )}
      {images?.length > 1 && (
        <div className="flex flex-wrap gap-5">
          {images?.map((image, i) => {
            return (
              <Image key={image.uid} image={image} className="rounded-lg" />
            );
          })}
        </div>
      )}
      {caption && (
        <Text className="mt-5 block text-xs italic text-black">{caption}</Text>
      )}
    </>
  );
};

CbImages.defaultProps = {};

export default CbImages;
