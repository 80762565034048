import React from "react";
import classNames from "classnames";
import { Text, Icon, Button } from "@atoms";
import Form from "./Form";

const CbForm = ({
  heading,
  descriptor,
  formid,
  download,
  color,
  unlocked,
  onSuccess,
}) => {
  const isDark =
    color === "pink" ||
    color === "purple" ||
    color === "teal" ||
    color === "darkTeal" ||
    color === "darkerTeal";

  if (unlocked && !download) return null;

  return (
    <div
      className={classNames(
        "relative overflow-hidden rounded-lg p-5 shadow-lg md:p-10",
        {
          "bg-teal": color === "teal",
          "bg-pink": color === "pink",
          "bg-purple": color === "purple",
        }
      )}
    >
      <div className="relative z-10 flex max-w-2xl flex-col items-center gap-5 sm:gap-10">
        <div className="flex flex-col items-center gap-5 text-center">
          <Text
            variant="h2"
            className={classNames("text-center", {
              "text-white": isDark,
            })}
          >
            {heading}
          </Text>
          <Text
            variant="body"
            className={classNames("text-center", {
              "text-white": isDark,
            })}
          >
            {descriptor}
          </Text>
        </div>
        {!unlocked && <Form onSuccess={onSuccess} formId={formid} />}
        {unlocked && download && (
          <Button download to={download}>
            Download
          </Button>
        )}
      </div>
      <Icon
        name="logoIcon"
        className="absolute -bottom-5 -right-5 h-auto w-48 -rotate-12 text-white opacity-10 md:w-64"
      />
    </div>
  );
};

CbForm.defaultProps = {};

export default CbForm;
