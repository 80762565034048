import React from "react";
// import classNames from "classnames";
import { Text, Icon, Button } from "@atoms";

const CbCta = ({ color, heading, descriptor, button }) => {
  return (
    <div className="relative overflow-hidden rounded-lg bg-black p-5 shadow-lg md:p-10">
      <div className="flex max-w-2xl flex-col items-center justify-center gap-5 text-center text-white">
        <Text variant="h2">{heading}</Text>
        <Text variant="body">{descriptor}</Text>
        {!!button?.url && (
          <Button to={button.url} color="pink">
            {button.text || "Learn More"}
          </Button>
        )}
      </div>
      <Icon
        name="logoIcon"
        className="absolute -bottom-5 -left-5 h-auto w-48 -rotate-12 text-white opacity-10 md:w-64"
      />
    </div>
  );
};

CbCta.defaultProps = {};

export default CbCta;
