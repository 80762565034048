import React from "react";
import { Button, ButtonAlt } from "@atoms";

const CbButton = ({ url, text, style }) => {
  if (style) {
    return <Button to={url}>{text}</Button>;
  }
  return <ButtonAlt to={url}>{text}</ButtonAlt>;
};

CbButton.defaultProps = {};

export default CbButton;
